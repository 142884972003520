import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useParams } from 'react-router-dom'
import PlaceholderLoading from 'react-placeholder-loading'

const Project = () => {

    const [post, setpost] = useState('')
    const [baseurl, setbaseurl] = useState([])

    const { urlid } = useParams()

    const getpost = async () => {
        const { data } = await axios.get(process.env.REACT_APP_IMAGE_URL + '/get_project/' + urlid)
        
        setpost(data)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getpost();
        setbaseurl(process.env.REACT_APP_IMAGE_URL)
    }, [])

    return (
        <div>
            <section className="section pt-55 ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 mb-20">

                            {!post &&
                                <div className="post-card">
                                    <div className="post-card-image">
                                        <PlaceholderLoading shape="rect" width={1500} height={400} colorStart={'#1D1D1D'} colorEnd={'#2B2B2B'} />
                                    </div>
                                    <div className="post-card-content">
                                        <div className="post-card-info">
                                            <ul className="list-inline">
                                                <li className="post-card-image sidec">
                                                    <PlaceholderLoading shape="rect" width={1500} height={5} colorStart={'#1D1D1D'} colorEnd={'#2B2B2B'} />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            {post &&
                                <div className="post-single">
                                    <div className="post-single-image">
                                        {baseurl &&
                                            <img src={baseurl + post.image_a} alt="" />
                                        }
                                    </div>
                                    <div className="post-single-content">
                                        <h4>{post.title}</h4>
                                        <div className="post-single-info">
                                            <ul className="list-inline">
                                                <li className="dot"></li>
                                                <li>{post.added_at}</li>
                                                <li className="dot"></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="post-single-body">
                                        <p dangerouslySetInnerHTML={{ __html: post.note }}>
                                        </p>

                                        <div className="row">

                                            {post.image_b &&
                                                <div className="col-md-6 ">
                                                    <div className="image">
                                                        <img src={baseurl + post.image_b_pair_x} alt="" />
                                                        <p>Caption can be used to add info</p>
                                                    </div>
                                                </div>
                                            }

                                            {post.image_c &&
                                                <div className="col-md-6 ">
                                                    <div className="image">
                                                        <img src={baseurl + post.image_c} alt="" />
                                                        <p>Caption can be used to add info</p>
                                                    </div>
                                                </div>
                                            }

                                            {post.image_d &&
                                                <div className="col-md-6 ">
                                                    <div className="image">
                                                        <img src={baseurl + post.image_d} alt="" />
                                                        <p>Caption can be used to add info</p>
                                                    </div>
                                                </div>
                                            }

                                            {post.image_e &&
                                                <div className="col-md-6 ">
                                                    <div className="image">
                                                        <img src={baseurl + post.image_e} alt="" />
                                                        <p>Caption can be used to add info</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Project;