import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import PlaceholderLoading from 'react-placeholder-loading'

const Projects = () => {

    const [posts, setposts] = useState([])
    const [baseurl, setbaseurl] = useState([])
    const [network, setnetwork] = useState(false)
    const navigate = useNavigate()

    const dummypost = [1, 2, 3, 4, 5, 6]

    const getposts = async (p = 1) => {
        await axios({
            method: 'GET',
            url: process.env.REACT_APP_IMAGE_URL + '/get_projects',
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {

            if (res.data.empty) {
                navigate('/404')
            } else {
                setposts(res.data)
            }

        }, (err) => {
            setnetwork(true)
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        setbaseurl(process.env.REACT_APP_IMAGE_URL)
        getposts();
    }, [])


    return (
        <div>
            {network &&
                <section className="section pt-85">
                    <div className="container-fluid">
                        <div className="newslettre-width text-center">
                            <div className="newslettre-info">
                                <h5>Network Error</h5>
                                <p> Check your network and try again </p>
                            </div>
                        </div>
                    </div>
                </section>
            }

            {posts.length == 0 && !network &&
                <section className="section pt-85">
                    <div className="container-fluid">
                        <div className="row">
                            {
                                dummypost.map((post, index) => (
                                    <div key={index} className="col-lg-4 col-md-6">

                                        <div className="post-card">
                                            <div className="post-card-image">
                                                <PlaceholderLoading shape="rect" width={700} height={200} colorStart={'#1D1D1D'} colorEnd={'#2B2B2B'} />
                                            </div>
                                            <div className="post-card-content">
                                                <div className="post-card-info">
                                                    <ul className="list-inline">
                                                        <li className="post-card-image sidec">
                                                            <PlaceholderLoading shape="rect" width={700} height={5} colorStart={'#1D1D1D'} colorEnd={'#2B2B2B'} />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </section>
            }
            <section className="section pt-85">
                <div className="container-fluid">
                    <div className="row">
                        {
                            posts.map((post, index) => (
                                <div key={index} className="col-lg-4 col-md-6">

                                    <div className="post-card">
                                        <div className="post-card-image">
                                            <Link to={'/project/' + post.urlid + '/' + post.title.replace(/ /g, '-')}>
                                                <img src={baseurl + post.image_a} alt="" />
                                            </Link>
                                        </div>
                                        <div className="post-card-content">
                                            <h5>
                                                <a>{post.title}</a>
                                            </h5>
                                            <p dangerouslySetInnerHTML={{ __html: post.note.substring(0, 100) }}>
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Projects;