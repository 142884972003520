import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaLinkedin, FaTwitter, FaGithubSquare } from "react-icons/fa";

const Header = () => {

    const openSide = () => {
        document.getElementById("sidebar").style.display = "block";
    }
    return (
        <div>
            <nav className="navbar navbar-expand-lg fixed-top">
                <div className="container-fluid">

                    <div className="logo">
                        <NavLink to="/">
                            <img src="/assets/logo-white.png" alt="" className="logo-dark" />
                        </NavLink>
                    </div>
                    <div className="collapse navbar-collapse" id="main_nav">
                        <ul className="navbar-nav ml-auto mr-auto">

                            <li className="nav-item">
                                <NavLink to="/about" className="nav-link">
                                    About
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/projects" className="nav-link">
                                    Projects
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/" className="nav-link">
                                    Build with me
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/contact" className="nav-link">
                                    Contact
                                </NavLink>
                            </li>

                        </ul>
                    </div>

                    <div className="navbar-right ml-auto">
                        {/* <div className="theme-switch-wrapper">
                            <label className="theme-switch" htmlFor='checkbox'>
                                <input type="checkbox" id="checkbox" />
                                <div className="slider round"></div>
                            </label>
                        </div> */}

                        <div className="social-icones">
                            <ul className="list-inline">
                                <li>
                                    <a href="https://github.com/papa-joe">
                                        <FaGithubSquare />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/RastamanCovie">
                                        <FaTwitter />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/in/covie/">
                                        <FaLinkedin />
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <button onClick={openSide} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Header;