import logo from './logo.svg';
import './App.css';
import './assets/bt.min.css'
import './assets/main.css'
import './assets/owl.cr.css'
import './assets/style.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import Home from './components/home';
import Story from './components/story';
import About from './components/about';
import Resume from './components/resume';
import Contact from './components/contact';
import Projects from './components/projects';
import Project from './components/project';
import Notfound from './components/404';
import Sidebar from './components/sidebar';
import { Redirect } from 'react-router';

function App() {
  return (
    <div className="dark">
      <Router>
        <Header />
        <Sidebar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/story/:cat/:title/:urlid" element={<Story />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/resume" element={<Resume />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/projects" element={<Projects />} />
          <Route exact path="/project/:urlid/:title" element={<Project />} />
          <Route exact path="/404" element={<Notfound />} />
          <Route path='*' exact={true} element={<Notfound />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
