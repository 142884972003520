import React from 'react';

const Footer = () => {
    return (
        <div>
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="copyright">
                                <p>© Copyright 2022  <a>AKPOME</a> , All rights reserved.</p>
                                <p>oviecovie@gmail.com</p>
                            </div>
                            <div className="back">
                                <a className="back-top">
                                    <i className="arrow_up"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;