import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link, useParams } from 'react-router-dom'
import { Modal, Spinner } from 'react-bootstrap';
import ReactAudioPlayer from 'react-audio-player';
import Prism from "prismjs";
import '../assets/prsm.css'
import PlaceholderLoading from 'react-placeholder-loading'

const Story = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [comalert, setcomalert] = useState(false);

    const [post, setpost] = useState('')
    const [posts, setposts] = useState([])
    const [comments, setcomments] = useState([])
    const [err, seterr] = useState([])
    const [baseurl, setbaseurl] = useState([])

    const [name, setname] = useState('')
    const [email, setemail] = useState('')
    const [message, setmessage] = useState('')
    const [network, setnetwork] = useState(false)

    const dummypost = [1, 2, 3, 4, 5, 6]

    const { urlid } = useParams()

    const getpost = async () => {
        const { data } = await axios.get(process.env.REACT_APP_IMAGE_URL + '/get_post/' + urlid)
        setpost(data)
        getcomments(data.id)
        var t = data.tags?.split(" ");
        Prism.highlightAll()
    }

    const getposts = async () => {
        const res = await axios.get(process.env.REACT_APP_IMAGE_URL + '/get_latest_posts')
        setposts(res.data)
    }

    const getcomments = async (d) => {
        const res = await axios.get(process.env.REACT_APP_IMAGE_URL + '/get_comments/' + d)
        setcomments(res.data)
    }

    const addcomment = async () => {
        handleShow()
        setcomalert(false)
        let formfield = new FormData()

        if (name !== '') {
            formfield.append('name', name)
        }

        formfield.append('email', email)
        formfield.append('message', message)
        formfield.append('post', post.id)

        await axios({
            method: 'POST',
            url: process.env.REACT_APP_IMAGE_URL + '/add_comment',
            data: formfield,
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            handleClose()

            if (res.data.data) {
                seterr(res.data.data)
            } else {
                setmessage('')
                setname('')
                setemail('')
                setcomalert(true)
                getcomments(post.id)
            }

        }, (err) => {
            handleClose()
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getpost()
        setbaseurl(process.env.REACT_APP_IMAGE_URL)
        getposts();

    }, [urlid])

    return (
        <div>

            <section className="section pt-55 ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 mb-20">

                            {!post &&
                                <div className="post-card">
                                    <div className="post-card-image">
                                        <PlaceholderLoading shape="rect" width={1000} height={400} colorStart={'#1D1D1D'} colorEnd={'#2B2B2B'} />
                                    </div>
                                    <div className="post-card-content">
                                        <div className="post-card-info">
                                            <ul className="list-inline">
                                                <li className="post-card-image sidec">
                                                    <PlaceholderLoading shape="rect" width={700} height={5} colorStart={'#1D1D1D'} colorEnd={'#2B2B2B'} />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }



                            <div className="post-single">
                                <div className="post-single-image">
                                    {baseurl && post.image_a &&
                                        <img src={baseurl + post.image_a} alt="" />
                                    }
                                </div>
                                <div className="post-single-content">
                                    <h4>{post.title}</h4>
                                    <div className="post-single-info">
                                        {post &&
                                            <ul className="list-inline">
                                                <li><a><img src={baseurl + post.author_image} alt="" /></a></li>
                                                <li><a>{post.author_name}</a> </li>
                                                <li className="dot"></li>
                                                <li>{post.post_date}</li>
                                                <li className="dot"></li>
                                            </ul>
                                        }
                                    </div>
                                </div>

                                <div className="post-single-body">


                                    <p dangerouslySetInnerHTML={{ __html: post.story_a }}>
                                    </p>

                                    {post.snippet_a &&
                                        <pre>
                                            <code className="language-javascript">
                                                {post.snippet_a}
                                            </code>
                                        </pre>
                                    }

                                    {post.audio &&
                                        <div className="row">
                                            <div className="col-md-12 ">
                                                <ReactAudioPlayer
                                                    src={baseurl + post.audio}
                                                    autoPlay
                                                    controls
                                                    className="audpl"
                                                />
                                            </div>
                                        </div>
                                    }

                                    {post.video &&
                                        <div className="row">
                                            <div className="col-md-12 ">
                                                <div className='player-wrapper'>
                                                    <video width="100%" height="100%" controls>
                                                        <source src={baseurl + post.video} type="video/mp4" />
                                                        <source src={baseurl + post.video} type="video/ogg" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <p dangerouslySetInnerHTML={{ __html: post.story_b }}>
                                    </p>

                                    {post.snippet_b &&
                                        <pre>
                                            <code className="language-javascript">
                                                {post.snippet_b}
                                            </code>
                                        </pre>
                                    }

                                    {post.image_b &&
                                        <div className="row">
                                            <div className="col-md-12 ">
                                                <div className="image">
                                                    <img src={baseurl + post.image_b} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <p dangerouslySetInnerHTML={{ __html: post.story_c }}>
                                    </p>

                                    {post.snippet_c &&
                                        <pre>
                                            <code className="language-javascript">
                                                {post.snippet_c}
                                            </code>
                                        </pre>
                                    }

                                    {post.image_c &&
                                        <div className="row">
                                            <div className="col-md-12 ">
                                                <div className="image">
                                                    <img src={baseurl + post.image_c} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <p dangerouslySetInnerHTML={{ __html: post.story_d }}>
                                    </p>

                                    {post.snippet_d &&
                                        <pre>
                                            <code className="language-javascript">
                                                {post.snippet_d}
                                            </code>
                                        </pre>
                                    }

                                    {post.image_d &&
                                        <div className="row">
                                            <div className="col-md-12 ">
                                                <div className="image">
                                                    <img src={baseurl + post.image_d} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <p dangerouslySetInnerHTML={{ __html: post.story_e }}>
                                    </p>

                                    {post.snippet_e &&
                                        <pre>
                                            <code className="language-javascript">
                                                {post.snippet_e}
                                            </code>
                                        </pre>
                                    }

                                    {post.image_e &&
                                        <div className="row">
                                            <div className="col-md-12 ">
                                                <div className="image">
                                                    <img src={baseurl + post.image_e} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <p dangerouslySetInnerHTML={{ __html: post.story_f }}>
                                    </p>

                                    {post.snippet_f &&
                                        <pre>
                                            <code className="language-javascript">
                                                {post.snippet_f}
                                            </code>
                                        </pre>
                                    }

                                    {post.image_f &&
                                        <div className="row">
                                            <div className="col-md-12 ">
                                                <div className="image">
                                                    <img src={baseurl + post.image_f} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <p dangerouslySetInnerHTML={{ __html: post.story_g }}>
                                    </p>

                                    {post.snippet_g &&
                                        <pre>
                                            <code className="language-javascript">
                                                {post.snippet_g}
                                            </code>
                                        </pre>
                                    }

                                    {post.image_g &&
                                        <div className="row">
                                            <div className="col-md-12 ">
                                                <div className="image">
                                                    <img src={baseurl + post.image_g} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <p dangerouslySetInnerHTML={{ __html: post.story_h }}>
                                    </p>

                                    {post.snippet_h &&
                                        <pre>
                                            <code className="language-javascript">
                                                {post.snippet_h}
                                            </code>
                                        </pre>
                                    }

                                    {post.image_h &&
                                        <div className="row">
                                            <div className="col-md-12 ">
                                                <div className="image">
                                                    <img src={baseurl + post.image_h} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <p dangerouslySetInnerHTML={{ __html: post.story_i }}>
                                    </p>

                                    {post.snippet_i &&
                                        <pre>
                                            <code className="language-javascript">
                                                {post.snippet_i}
                                            </code>
                                        </pre>
                                    }

                                    {post.image_i &&
                                        <div className="row">
                                            <div className="col-md-12 ">
                                                <div className="image">
                                                    <img src={baseurl + post.image_i} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <p dangerouslySetInnerHTML={{ __html: post.story_j }}>
                                    </p>

                                    {post.snippet_j &&
                                        <pre>
                                            <code className="language-javascript">
                                                {post.snippet_j}
                                            </code>
                                        </pre>
                                    }

                                    {post.image_j &&
                                        <div className="row">
                                            <div className="col-md-12 ">
                                                <div className="image">
                                                    <img src={baseurl + post.image_j} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>

                                <div className="post-single-footer">
                                    <div className="tags">
                                        <ul className="list-inline">
                                            {/* {
                                                t.map((tag, index) => (
                                                    <li key={index}>
                                                        <a href="blog-grid.html">{tag}</a>
                                                    </li>
                                                ))
                                            } */}

                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {post &&
                                <div className="widget mb-50">
                                    <div className="title">
                                        <h5>Comments</h5>
                                    </div>
                                    <ul className="widget-comments">
                                        {
                                            comments.map((comment, index) => (
                                                <li key={index} className="comment-item">
                                                    <img className="cimg" src="/assets/avr.jpeg" alt="" />
                                                    <div className="content">
                                                        <ul className="info list-inline">
                                                            <li>{comment.name}</li>
                                                            <li className="dot"></li>
                                                            <li>{comment.post_date}</li>
                                                        </ul>
                                                        <p>{comment.message}
                                                        </p>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ul>

                                    <div className="title">
                                        <h5>Leave a Reply</h5>
                                    </div>
                                    <div>
                                        <form className="widget-form" action="#" method="POST" id="main_contact_form">
                                            <p>Your email adress will not be published ,Requied fileds are marked*.</p>
                                            {comalert &&
                                                <div className="alert alert-success contact_msg" role="alert">
                                                    Your message was sent successfully.
                                                </div>
                                            }

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <textarea name="message" id="message" cols="30" rows="5" className="form-control cm" placeholder="Message*" required="required" value={message} onChange={(e) => setmessage(e.target.value)}></textarea>
                                                        <small className="errdis">{err.message}</small>
                                                    </div>

                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="text" id="name" className="form-control cm" placeholder="Name*" required="required" name='name' value={name} onChange={(e) => setname(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="email" id="email" className="form-control cm" placeholder="Email*" required="required" name='email' value={email} onChange={(e) => setemail(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <button className="btn-custom" onClick={addcomment} >
                                                        Post Comment
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            }

                        </div>
                        <div className="col-lg-4 max-width">

                            <div className="widget ">
                                <div className="section-title">
                                    <h5>Latest Posts</h5>
                                </div>
                                <ul className="widget-latest-posts">
                                    {posts &&
                                        posts.map((post, index) => (
                                            <li key={index} className="last-post">
                                                
                                                <div className="image">
                                                <Link to={'/story/' + post.category + '/' + post.title.replace(/ /g, '-') + '/' + post.urlid}><span><img src={baseurl + post.image_a} alt="" /></span></Link>
                                                </div>
                                                <div className="content">
                                                    <p><Link to={'/story/' + post.category + '/' + post.title.replace(/ /g, '-') + '/' + post.urlid}><span>{post.title}</span></Link></p>
                                                    <small><span className="icon_clock_alt"></span> {post.post_date}</small>
                                                </div>
                                            </li>
                                        ))
                                    }

                                    {posts.length == 0 &&
                                        dummypost.map((post, index) => (
                                            <li key={index} className="last-post">
                                                <div className="image">
                                                    <a>
                                                    <PlaceholderLoading shape="rect" width={600} height={60} colorStart={'#1D1D1D'} colorEnd={'#2B2B2B'} />
                                                    </a>
                                                </div>
                                                <div className="content">
                                                    <p><a><PlaceholderLoading shape="rect" width={200} height={10} colorStart={'#1D1D1D'} colorEnd={'#2B2B2B'} /></a></p>
                                                    <small><span className="icon_clock_alt"></span> <PlaceholderLoading shape="rect" width={200} height={10} colorStart={'#1D1D1D'} colorEnd={'#2B2B2B'} /></small>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Modal
                show={show}
                onHide={handleClose}
                centered
                backdrop="static"
                keyboard={false}>
                <Modal.Body>
                    <center><Spinner animation="border" /></center>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Story;