import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import PlaceholderLoading from 'react-placeholder-loading'
import { Modal, Spinner } from 'react-bootstrap';
import { loadProgressBar } from 'axios-progress-bar'
import 'axios-progress-bar/dist/nprogress.css'


const Home = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [posts, setposts] = useState([])
    const [nextpage, setnext] = useState([])
    const [prevpage, setprev] = useState([])
    const [page, setpage] = useState([])
    const [baseurl, setbaseurl] = useState([])
    const [email, setemail] = useState('')
    const [network, setnetwork] = useState(false)
    const navigate = useNavigate()

    const dummypost = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]

    const getposts = async (p = 1) => {
        loadProgressBar()
        await axios({
            method: 'GET',
            url: process.env.REACT_APP_IMAGE_URL + '/get_posts?page_number=' + p,
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {

            if (res.data.empty) {
                navigate('/404')
            } else {
                setposts(res.data.data)
                setnext(res.data.next_page)
                setprev(res.data.previous_page)
                setpage(p)
            }

        }, (err) => {
            setnetwork(true)
        })

    }

    const prev = async () => {
        getposts(page - 1);
    }

    const next = async () => {
        getposts(page + 1);
    }

    const subscribe = async () => {
        if(email == ''){
            alert('Email is required')
            return
        }

        handleShow()

        let formfield = new FormData()

        formfield.append('email', email)

        await axios({
            method: 'POST',
            url: process.env.REACT_APP_IMAGE_URL + '/subscribe',
            data: formfield,
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {

            if (res.data.data) {
                console.log('complete')
            } else {
                setemail('')
            }

            handleClose()

        }, (err) => {
            alert('check ur network and try again')
            handleClose()
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        setbaseurl(process.env.REACT_APP_IMAGE_URL)
        getposts();
    }, [])

    return (
        <div>
            <section className="section carousel-hero">
                <div>
                    <div className="hero d-flex align-items-center zb">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                                    <div className="hero-content">
                                        <h2>
                                            <a>AKPOME MEANS MY LIFE </a>
                                        </h2>
                                        <p className="ht">Welcome to my crazy world</p>

                                        <div className="post-card-info">
                                            <ul className="list-inline">
                                                <li>Welcome to my crazy world</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {network &&
                <section className="section pt-85">
                    <div className="container-fluid">
                        <div className="newslettre-width text-center">
                            <div className="newslettre-info">
                                <h5>Network Error</h5>
                                <p> Check your network and try again </p>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {posts.length == 0 && !network &&
                <section className="section">
                    <div className="container-fluid">
                        <div className="row">
                            {
                                dummypost.map((post, index) => (
                                    <div key={index} className="col-lg-4 col-md-6">

                                        <div className="post-card">
                                            <div className="post-card-image">
                                                <PlaceholderLoading shape="rect" width={700} height={200} colorStart={'#1D1D1D'} colorEnd={'#2B2B2B'} />
                                            </div>
                                            <div className="post-card-content">
                                                <div className="post-card-info">
                                                    <ul className="list-inline">
                                                        <li className="post-card-image sidec">
                                                            <PlaceholderLoading shape="rect" width={700} height={5} />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </section>
            }

            <section className="section rs">
                <div className="container-fluid">
                    <div className="row">
                        {
                            posts.map((post, index) => (
                                <div key={index} className="col-lg-4 col-md-6">

                                    <div className="post-card">
                                        <div className="post-card-image">
                                            <Link to={'/story/' + post.category + '/' + post.title.replace(/ /g, '-') + '/' + post.urlid}>
                                                <img src={baseurl + post.image_a} alt="" />
                                            </Link>
                                        </div>
                                        <div className="post-card-content">
                                            <h5>
                                                <Link to={'/story/' + post.category + '/' + post.title.replace(/ /g, '-') + '/' + post.urlid}>
                                                    <span>{post.title}</span>
                                                </Link>
                                            </h5>
                                            <p dangerouslySetInnerHTML={{ __html: post.story_a.substring(0, 150) }}>
                                            </p>
                                            <div className="post-card-info">
                                                <ul className="list-inline">
                                                    <li>
                                                        <a>
                                                            <img src={baseurl + post.author_image} alt="" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a>{post.author_name}</a>
                                                    </li>
                                                    <li className="dot"></li>
                                                    <li>{post.post_date}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            ))
                        }



                        <div className="col-lg-12">
                            <div className="pagination mt--10">
                                <ul className="list-inline">
                                    {prevpage === true &&
                                        <li onClick={() => prev()} className="active">
                                            <a>previous</a>
                                        </li>
                                    }

                                    {nextpage === true &&
                                        <li onClick={() => next()} className="active">
                                            <a>next</a>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {!network &&
                <section className="newslettre">
                    <div className="container-fluid">
                        <div className="newslettre-width text-center">
                            <div className="newslettre-info">
                                <h5>Subscribe to our Newslatter</h5>
                                <p> Sign up for free and be the first to get notified about new posts. </p>
                            </div>
                            <div className="newslettre-form">
                                <div className="form-flex">
                                    <div className="form-group">
                                        <input type="email" className="form-control" placeholder="Your email adress" required="required" name='email' value={email} onChange={(e) => setemail(e.target.value)} />
                                    </div>
                                    <button className="submit-btn" onClick={subscribe}>Subscribe</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            <Modal
                show={show}
                onHide={handleClose}
                centered
                backdrop="static"
                keyboard={false}>
                <Modal.Body>
                    <center><Spinner animation="border" /></center>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Home;