import React, { useEffect } from "react";

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <section className="section pt-55 ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4  mb-20 max-width">

                            <div className="widget ">
                                <div className="section-title">
                                    <h5>Contact me</h5>
                                </div>
                                <img src="/assets/dpp.jpeg" alt="" />
                            </div>





                        </div>
                        <div className="col-lg-8 mb-20">
                            <div className="widget">
                                <div className="post-single-content">
                                    <p>Feel free to contact me via any of the following medium, i respond to all messages as soon as i can.</p>
                                    <h6>EMAIL</h6>
                                    <p>oviecovie@gmail.com</p>

                                    <h6>Linkedin</h6>
                                    <p><a href="https://www.linkedin.com/in/covie/">Linkedin</a></p>

                                    <h6>Twitter</h6>
                                    <p><a href="https://twitter.com/RastamanCovie">@RastamanCovie</a></p>

                                </div>
                            </div>



                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
};

export default Contact;