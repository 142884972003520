import React, { useEffect } from "react";

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <section className="section pt-55 ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4  mb-20 max-width">

                            <div className="widget ">
                                <div className="section-title">
                                    <h5>About me</h5>
                                </div>
                                <img src="/assets/dpp.jpeg" alt="" />
                            </div>



                            {/* <div className="widget">
                                <div className="section-title">
                                    <a className="categorie">SKILLS</a>
                                    <div className="gap"></div>

                                    <h6>LANGUAGES</h6>
                                    <div className="post-single-info">
                                        <ul className="list-inline">
                                            <li>Javascript</li>
                                            <li className="dot"></li>
                                            <li>Python</li>
                                            <li className="dot"></li>
                                            <li>PHP</li>
                                            <li className="dot"></li>
                                            <li>Solidity</li>
                                            <li className="dot"></li>
                                            <li>Rust</li>
                                        </ul>
                                    </div>

                                    <div className="gap"></div>

                                    <h6>FRAMEWORKS</h6>
                                    <div className="post-single-info">
                                        <ul className="list-inline">
                                            <li>Django</li>
                                            <li className="dot"></li>
                                            <li>Laravel</li>
                                            <li className="dot"></li>
                                            <li>Expressjs</li>
                                            <li className="dot"></li>
                                            <li>Reactjs</li>
                                            <li className="dot"></li>
                                            <li>Angular</li>
                                            <li className="dot"></li>
                                            <li>Anchor</li>
                                        </ul>
                                    </div>

                                    <div className="gap"></div>

                                    <h6>DATABASES</h6>
                                    <div className="post-single-info">
                                        <ul className="list-inline">
                                            <li>MySql</li>
                                            <li className="dot"></li>
                                            <li>Postgres</li>
                                            <li className="dot"></li>
                                            <li>SQLite</li>
                                            <li className="dot"></li>
                                            <li>MongoDB</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                        <div className="col-lg-8 mb-20">
                            <div className="widget">
                                {/* <div className="post-single-image">
                                    <img src="/assets/dp.jpeg" alt="" />
                                </div> */}
                                <div className="post-single-content">
                                    <p>Before i started writting codes i taught physics, chemistry and biology to high school and university students, i love how science does'nt care about anybody's opinion, it is either correct or incorrect</p>

                                    <p>When i started coding i applied some of the logic i used in learning and teaching physics and chemistry, but i quickly realised that while most of the knowledge in those fields are fixed, coding wasnt like that, it changes frequently, to catch up i stood on the shoulders of giant and i did see farther</p>

                                    <p>I learn mainly from reading books and documentations, i code most of my projects using javascript, react, nodejs, python and solidity</p>

                                    <p>I work with mysql, sqlite, postgresql and mongodb database</p>

                                    <p>I have built projects for edtech, fintech, betting, management and crypto space</p>

                                    <p>I built this Website to be able to share some of my design process with other developers, i built it using react and django.</p>

                                    <p>FYI i am a strong liverpool fan.</p>
                                    
                                </div>
                            </div>



                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
};

export default About;