import React, { useEffect } from "react";

const Resume = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <section className="section pt-55 ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4  mb-20 max-width">
                            <div className="widget">
                                <div className="section-title">
                                    <a className="categorie">SKILLS</a>
                                    <div className="gap"></div>

                                    <h6>LANGUAGES</h6>
                                    <div className="post-single-info">
                                        <ul className="list-inline">
                                            <li>Javascript</li>
                                            <li className="dot"></li>
                                            <li>Python</li>
                                            <li className="dot"></li>
                                            <li>PHP</li>
                                            <li className="dot"></li>
                                            <li>Solidity</li>
                                            <li className="dot"></li>
                                            <li>Rust</li>
                                        </ul>
                                    </div>

                                    <div className="gap"></div>

                                    <h6>FRAMEWORKS</h6>
                                    <div className="post-single-info">
                                        <ul className="list-inline">
                                            <li>Django</li>
                                            <li className="dot"></li>
                                            <li>Laravel</li>
                                            <li className="dot"></li>
                                            <li>Expressjs</li>
                                            <li className="dot"></li>
                                            <li>Reactjs</li>
                                            <li className="dot"></li>
                                            <li>Angular</li>
                                            <li className="dot"></li>
                                            <li>Anchor</li>
                                        </ul>
                                    </div>

                                    <div className="gap"></div>

                                    <h6>DATABASES</h6>
                                    <div className="post-single-info">
                                        <ul className="list-inline">
                                            <li>MySql</li>
                                            <li className="dot"></li>
                                            <li>Postgres</li>
                                            <li className="dot"></li>
                                            <li>SQLite</li>
                                            <li className="dot"></li>
                                            <li>MongoDB</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-8 mb-20">
                            <div className="widget">
                                <a className="categorie">EXPERIENCE</a>
                                <div className="gap"></div>


                                <h6>SARUTECH, - Fullstack developer and Academy coordinator</h6>
                                <span>JUNE 2018 - PRESENT FULL TIME  ---{'>'} <a href="https://sarutech.com/"> View website</a></span>
                                <div className="post-single-info">
                                    <ul className="list-inline">
                                        <li>Building production-ready mobile and web Applications</li>
                                        <li className="dot"></li>
                                        <li>Fixing bugs</li>
                                        <li className="dot"></li>
                                        <li>Writing API.</li>
                                        <li className="dot"></li>
                                        <li>Building and maintaining Databases.</li>
                                        <li className="dot"></li>
                                        <li>Teaching students.</li>
                                    </ul>
                                </div>
                                <div className="gap"></div>

                                <h6>Osiomax, — Lead web and Mobile developer</h6>
                                <span>JUNE 2018 - PRESENT FULL TIME  ---{'>'} <a href="https://osiomax.com/"> View website</a></span>
                                <div className="post-single-info">
                                    <ul className="list-inline">
                                        <li>Built base code</li>
                                        <li className="dot"></li>
                                        <li>Adding new features</li>
                                        <li className="dot"></li>
                                        <li>Fixing bugs</li>
                                        <li className="dot"></li>
                                        <li>Writing API.</li>
                                        <li className="dot"></li>
                                        <li>Building and maintaining Databases.</li>
                                    </ul>
                                </div>
                                <div className="gap"></div>

                                <h6>Learnr (Edtech), — Fullstack developer (Backend heavy)</h6>
                                <span>NOVEMBER 2021 - FEBRUARY 2022 FULL TIME  ---{'>'} <a href="http://learnr.com.ng/"> View website</a></span>
                                <div className="post-single-info">
                                    <ul className="list-inline">
                                        <li>Adding new features</li>
                                        <li className="dot"></li>
                                        <li>Fixing bugs</li>
                                        <li className="dot"></li>
                                        <li>Writing API.</li>
                                        <li className="dot"></li>
                                        <li>Building and maintaining Databases.</li>
                                    </ul>
                                </div>
                                <div className="gap"></div>

                                <h6>Arich (Fintech), — Frontend developer</h6>
                                <span>CONTRACT</span>
                                <div className="post-single-info">
                                    <ul className="list-inline">
                                        <li>Built admin dashboard</li>
                                        <li className="dot"></li>
                                        <li>Fixing bugs</li>
                                        <li className="dot"></li>
                                        <li>Consuming API.</li>
                                    </ul>
                                </div>
                                <div className="gap"></div>

                                <h6>Agilfinance (Fintech), — Fullstack developer</h6>
                                <span>CONTRACT</span>
                                <div className="post-single-info">
                                    <ul className="list-inline">
                                        <li>Built loan management system</li>
                                        <li className="dot"></li>
                                        <li>Fixing bugs</li>
                                        <li className="dot"></li>
                                        <li>Consuming API.</li>
                                        <li className="dot"></li>
                                        <li>Writing API.</li>
                                        <li className="dot"></li>
                                        <li>Database management</li>
                                    </ul>
                                </div>
                                <div className="gap"></div>

                                <h6>Euzzit (Fintech), — Backend developer</h6>
                                <span>CONTRACT  ---{'>'} <a href="http://www.euzzit.com/"> View website</a></span>
                                <div className="post-single-info">
                                    <ul className="list-inline">
                                        <li>Restructured api and database</li>
                                        <li className="dot"></li>
                                        <li>Fixing bugs</li>
                                        <li className="dot"></li>
                                        <li>Adding new fixtures.</li>
                                        <li className="dot"></li>
                                        <li>Database management</li>
                                    </ul>
                                </div>
                                <div className="gap"></div>
                            </div>



                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
};

export default Resume;